/* src/App.css */

/* General styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #000000; /* Dark background */
  color: #f0f4f8; /* Light text */
  margin: 0;
  padding: 0;
  line-height: 1.5;  /* 1.3-1.5 is good for readability */
  font-size: 14px; /* Adjust as needed */}

table {
  width: 70%;
  font-size: 14px; /* Font size 12px for all text */
}
thead {
  text-align: left;
}

/* Container for the whole app */
.app {  color: #ecf0f1; /* Light text */

  display: flex;
  height: 100vh;
  overflow: hidden;
}

.email-list-container {
  width: 40%;
  border-right: 1px solid #ccc;
  background-color: black; /* Darker background */
  /* overflow-y: auto; */
}

/* Custom scrollbar styles */
.infinite-scroll-component::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.infinite-scroll-component::-webkit-scrollbar-track {
  background: #ffffff; /* White background for the track */
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: #000000; /* Black color for the thumb */
  border-radius: 10px; /* Round the corners of the thumb */
  border: 3px solid #ffffff; /* Adds space around the thumb */
}

.infinite-scroll-component{
  padding-left: 20px;
  padding-right: 10px;
}
/* Right side container for email detail */
.email-detail-container {
  width: 70%;
  padding: 20px;
  background-color: black; /* Darker background */
  overflow-y: auto;
  overflow-x: auto;
  color: #ecf0f1; /* Light text */
  border-right: 1px solid #ccc;
}

/* Custom scrollbar styles */
.email-detail-container::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.email-detail-container::-webkit-scrollbar-track {
  background: #ffffff; /* White background for the track */
}

.email-detail-container::-webkit-scrollbar-thumb {
  background-color: #000000; /* Black color for the thumb */
  border-radius: 10px; /* Round the corners of the thumb */
  border: 3px solid #ffffff; /* Adds space around the thumb */
}

/* Styling for the email list */
.email-list {
  margin: 0;
  padding: 0px;
}

.email-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.email-list li {
  cursor: pointer;
  padding: 15px 10px;
  margin-bottom: 10px;
  background-color: #181b1f; /* Darker background */
  border: 1px solid black; /* Match background for border */
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ecf0f1; /* Light text */
  font-size: 14px; /* Font size 12px for all text */
}

.email-list li:hover {
  background-color: black; /* Highlight color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.email-list h3 {
  margin: 0;
  font-size: 1.1em;
  font-size: 14px; /* Font size 12px for all text */
}

.email-list p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #bdc3c7; /* Lighter text */
  font-size: 14px; /* Font size 12px for all text */
}

/* Styling for the email detail */
.email-detail {
  background-color: #181b1f; /* Darker background */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #ecf0f1; /* Light text */
  font-size: 14px; /* Font size 12px for all text */
}

.email-detail h2 {
  margin-top: 0;
  color: #ecf0f1; /* Light text */
}

.email-detail h3 {
  margin-top: 0;
}

.email-detail p {
  margin-top: -20px;
  /* line-height: 1.6; */
  font-size: 14px; /* Font size 12px for all text */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}

.email-detail pre ol > li > p,
.email-detail pre ul > li > p{
  margin-top: auto;
  margin-bottom: -14px;
}

.email-detail pre {
  /* line-height: 1.6; */
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  overflow-y: hidden; /* Hide vertical overflow */
  word-wrap: break-word; /* Wrap long words */
  max-width: 100%; /* Ensure content doesn't exceed container width */
  white-space: pre-wrap; /* Preserve whitespace and wrap lines */
  font-size: 14px; /* Font size 12px for all text */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";

}

.email-detail ol,
.email-detail ul {
  margin-bottom: 0px;
  line-height: 0.75;
  padding-left: 20px;
}

.email-detail pre > ol,
.email-detail pre > ul {
  margin-top: -34px;
}

.email-detail pre > ol ol,
.email-detail pre > ul ul,
.email-detail pre > ol ul,
.email-detail pre > ul ol{
  margin-bottom: -20px;
}

.email-detail li {
  /* margin-top: 2px !important; */
  line-height: 1.25 !important;
}

.email-detail p:first-of-type {
  /* font-weight: bold; */
  color: white; /* Highlight color */
}

.email-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white; /* Adjust color as needed */
  font-size: 1.2em;
  margin-left: 10px; /* Adjust spacing as needed */
}

.copy-button:hover {
  color: #181b1f; /* Adjust color as needed */
}

/* Search and sort container */
.search-sort-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px;
}

.search-container {
  position: relative;
  flex: 1;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #181b1f; /* Darker background */
  color: #ecf0f1; /* Light text */
  font-size: 14px; /* Font size 12px for all text */
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.sort-button {
  background-color: black; /* Button color */
  color: white; /* Text color */
  border: none;
  margin-left: 3px;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sort-button:hover {
  background-color: #181b1f; /* Hover color */
}

.sort-icon {
  margin-right: 5px;
}

.sticky {
  position: sticky;
  background-color: #000000;
  padding-top: 20px;
  padding-bottom: 0px;
  top: 0;
  /* z-index: 10; */
  margin-bottom: 10px !important;
}

.tooltip-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background: none;
  color: white;
  border: none;
}

.tooltip-button .tooltip-text {
  visibility: hidden;
  width: 60px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -30px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-button .tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.fa {
  font-size: 20px;
}

@media print {
  @page {
    margin: 1cm; /* Set the margin for top, bottom, left, and right */
  }

  body {
    margin: 0;
    padding: 0;
  }
  span {
    background-color: white !important;
  }
  
  *{
    color: black !important;
  }

  .print-container {
    background-color: white;
    color: black;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  .print-container h2 {
    color: black;
  }

  /* .print-container * {
    page-break-inside: avoid;
    margin: 0;
    padding: 0;
  }

  .print-container .section {
    page-break-before: always;
  } */

  /* Handle any potential large margins or paddings */
  .print-container .content {
    margin: 0;
    padding: 0;
  }
}

/* src/App.css */
.passcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text color */
}

.passcode-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e; /* Slightly lighter dark background for form */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.passcode-container input {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  background-color: #333333; /* Dark input background */
  color: #ffffff; /* Light text color */
  border: 1px solid #555555; /* Dark border */
  border-radius: 4px;
}

.passcode-container input::placeholder {
  color: #bbbbbb; /* Light placeholder color */
}

.passcode-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: white; /* Primary dark button color */
  color: black; /* Light text color */
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.passcode-container button:hover {
  background-color: black; /* Darker hover color */
  color: white;
  border-color: black;
  border: solid;
}

table thead th{
  min-width: 80px !important;
}


/* Loader CSS Start  */


/* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  margin-left: 16px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.loader-container{
  display: flex;
  justify-content: center;
}

.loader-container .loader{
  margin-left: 0px;
  width: 60px;
}

@keyframes bgFlash {
  0% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
}

.bg-flash {
  animation: bgFlash 1s ease-out;
  animation-delay: 500ms; /* Adjust the delay as needed */
}

.category-dropdown {
  background-color: #1e1e1e; /* Same dark background as the search input */
  color: #fff; /* White text */
  border: 1px solid #666; /* Match the search input border */
  padding: 8px; /* Adjust padding for better appearance */
  font-size: 14px; /* Ensure consistency with the search input text size */
  border-radius: 4px; /* Make sure the dropdown has a similar rounded corner */
  margin-left: 3px;
}

.category-dropdown:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #888; /* Slightly lighter border on focus */
}

.category-dropdown option {
  background-color: #1e1e1e; /* Match the dropdown background */
  color: #fff; /* Keep the text color consistent */
}


/* Loader CSS End  */